import M$control$IGNSearchLocatorControl from './facade/js/ignsearchlocatorcontrol';
import M$control$InfoCatastroControl from './facade/js/infocatastrocontrol';
import M$plugin$Locator from './facade/js/locator';
import M$control$LocatorControl from './facade/js/locatorcontrol';
import M$control$XYLocatorControl from './facade/js/xylocatorcontrol';
import M$impl$control$IGNSearchLocatorControl from './impl/ol/js/ignsearchlocatorcontrol';
import M$impl$control$InfoCatastroControl from './impl/ol/js/infocatastrocontrol';
import M$impl$control$LocatorControl from './impl/ol/js/locator';
import M$impl$control$XYLocatorControl from './impl/ol/js/xylocatorcontrol';

if (!window.M.control) window.M.control = {};
if (!window.M.plugin) window.M.plugin = {};
if (!window.M.impl) window.M.impl = {};
if (!window.M.impl.control) window.M.impl.control = {};
window.M.control.IGNSearchLocatorControl = M$control$IGNSearchLocatorControl;
window.M.control.InfoCatastroControl = M$control$InfoCatastroControl;
window.M.plugin.Locator = M$plugin$Locator;
window.M.control.LocatorControl = M$control$LocatorControl;
window.M.control.XYLocatorControl = M$control$XYLocatorControl;
window.M.impl.control.IGNSearchLocatorControl = M$impl$control$IGNSearchLocatorControl;
window.M.impl.control.InfoCatastroControl = M$impl$control$InfoCatastroControl;
window.M.impl.control.LocatorControl = M$impl$control$LocatorControl;
window.M.impl.control.XYLocatorControl = M$impl$control$XYLocatorControl;
